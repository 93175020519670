.input-phone {
	display: flex;
	width: 100%;
	height: 52px;
	align-items: center;
	border-radius: 3px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: none !important;
}

.input-phone:hover {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: none !important;
}

.input-phone:focus {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: none !important;
}

.input {
	max-width: 100%;
	width: 100%;
	height: 3.25rem !important;
	align-items: center;
	border-radius: 0.375rem !important;
	border: 1px solid #212121 !important;
	background: var(--White, #fff);
	box-shadow: none !important;
}

.input:hover {
	border: 1px solid #147CBC !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
}

.input:focus {
	border: 1px solid #147CBC !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
}

.input-borderless:focus {
	max-width: 100%;
	width: 100%;
	align-items: center;
	border: none !important;
	border-color: transparent !important;
	outline: 0px !important;
	outline-offset: 0px !important;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: none !important;
	--tw-ring-color: none !important;
	--tw-ring-offset-shadow: 0px !important;
	--tw-ring-shadow: 0px !important;
	box-shadow: none !important;
	border-color: transparent !important;
}

.input-login {
	max-width: 100%;
	width: 22.34531rem;
	height: 3.125rem;
	flex-shrink: 0;
	align-items: center;
	border-radius: 0.314rem;
	border: 0.837px solid var(--Input-Field-Pressed, #1B47B4);
	background: transparent !important;
	box-shadow: none !important;
}

.input-login-password {
	max-width: 100%;
	width: 22.34531rem;
	height: 3.125rem;
	flex-shrink: 0;
	align-items: center;
	border-radius: 0.314rem;
	border: 0.837px solid var(--Text-primary, #212121);
	background: transparent !important;
	box-shadow: none !important;
}

.search-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 2rem;
	padding: 1.8125rem 1.875rem;
	gap: 0.5rem;
	border-radius: 3.125rem;
	background: #FFF;
	box-shadow: 0px 0px 40px 0px rgba(26, 28, 40, 0.07);
	color: #626364;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
}


.ant-select-selector {
	min-height: 3.25rem !important;
	max-width: 150%;
	min-width: 100px !important;
	width: 100%;
	align-items: center;
	border-radius: 0.375rem !important;
	border: 1px solid #212121 !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
	/* padding-bottom: 0.7rem !important; */
	padding: 11px 11px;
}

.ant-select {
	min-height: 3.25rem !important;
	min-width: 150px !important;
	max-width: 100%;
	width: 100%;
	align-items: center !important;
	border: none !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
}

.select-borderless .ant-select-selector {
	max-width: 150%;
	min-width: 100px !important;
	width: 100%;
	align-items: center;
	border-radius: 0px !important;
	border: 0px !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
	padding: 11px 11px;
}

.select-borderless .ant-select {
	min-width: 150px !important;
	max-width: 100%;
	width: 100%;
	align-items: center !important;
	border: none !important;
	background: var(--White, #fff) !important;
	box-shadow: none !important;
}

.date-borderless {
	border: 0px !important;
	min-width: 100px !important;
	width: 100% !important;
}

.ant-select-arrow {
	/* top: 13.5px !important; */
	color: #212121 !important;
}

.ant-input-suffix .anticon {
	color: #212121 !important;
}

.anticon {
	color: white !important;
}

.anticon-check-circle {
	color: #02A548 !important;
}

.anticon-linkedin {
	color: #212121 !important;
}

.anticon-global {
	color: #212121 !important;
}

.ant-form-item {
	margin-bottom: 2.62rem !important;
}

.table-form-item {
	margin-bottom: 0px !important;
}

.ant-form-item-label {
	padding: 0 !important;
}

.ant-input-disabled {
	background-color: #fff !important;
}

.editable-cell-value-wrap {
	display: flex !important;
	align-items: center !important;
	justify-content: flex-start !important;
	min-height: 44px !important;
	padding-right: 0px !important;
}

.ant-popconfirm-message {
	display: none !important;
}

.ant-popconfirm-buttons button {
	box-shadow: none !important;
	margin-inline-start: 0px !important;
}

/* .ant-drawer-mask{
	height: 100vh !important;
	background: transparent !important;
}

.ant-drawer{
	position: inherit !important;
	height: 100vh !important;
} */

.menu-float-btn {
	z-index: 10000;
	background-color: #fff !important;
	width: 93px !important;
	height: 93px !important;
	top: 255px !important;
	left: 10px !important;
}

.menu-float-btn .ant-float-btn-icon {
	width: 100% !important;
}

.editable-table-check .ant-checkbox {
	/* margin-top: -68% !important; */
}

.editable-table-check .ant-checkbox-inner {
	width: 1.25rem !important;
	height: 1.25rem !important;
	border-radius: 0.125rem !important;
	border: 1px solid var(--Gray-400, #98A2B3) !important;
}

.no-hover .ant-table-cell-row-hover {
	background: none !important;
}

.ant-table-thead {
	background: #F2FAFF !important;
}

.ant-notification-notice-close::before {
	content: url(./assets/cancel.svg) !important;
	color: black !important;
	font-size: 18px !important;
}

.ant-notification-notice-with-icon .anticon {
	color: #212121 !important;
}

.ant-pagination {
	width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-pagination-item-link {
	background: black !important;
	border-radius: 6.25rem !important;
	border: 1px solid #CCC !important;
	background: #1A1C28 !important;
	display: flex !important;
	width: 7.16688rem !important;
	max-width: 100% !important;
	padding: 0.8125rem 0rem 0.76188rem 0rem !important;
	justify-content: center !important;
	align-items: center !important;
	gap: -0.00063rem !important;
}

.ant-pagination-jump-nex {
	display: none !important;
}

.ant-pagination-jump-prev {
	display: none !important;
}

.ant-pagination-prev {
	margin-right: 10px !important;
}

/* .ant-pagination-simple-pager{
	display: flex !important;
	justify-content: flex-end !important;
	margin-right: 3.68rem !important;
}

.ant-pagination-prev{
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-pagination-next{
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
} */

.ant-breadcrumb-link {
	color: var(--Text-secondary, #616161);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.ant-breadcrumb-link {
	color: var(--Icon-active, #1B47B4);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.ant-breadcrumb li {
	list-style-type: none !important;
}

.ant-form-item-extra {
	color: var(--Button-primary, #1B47B4) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}
