@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Light.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Light.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Light.ttf") format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Regular.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf") format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Medium.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf") format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype');
	font-weight: 700;
}

.splide {
	width: 100% !important;
}

.splide__track {
	/* padding-left: 70px !important;
	padding-right: 70px !important; */
	padding-bottom: 10px !important;
}


.splide__arrows {
	display: none !important;
}

.splide__arrow--prev {
	left: 0em !important;
}

.splide__arrow {
	background: #EDF8FF !important;
	padding: 0.44rem !important;
	border: 1px solid #1B47B4 !important;
	z-index: 10000;
	opacity: 1;
}

.splide__arrow>svg {
	fill: #1B47B4 !important;
}

.splide__pagination {
	bottom: -1.56rem !important;
	/* @apply !justify-start xl: !justify-center */
}


.splide__pagination__page {
	width: 0.625rem !important;
	height: 0.625rem !important;
	background: rgba(230, 234, 243, 0.5) !important;
}

.splide__pagination__page.is-active {
	background: #ffffff !important;
}

.custom-splide .splide__pagination__page {
	width: 0.625rem !important;
	height: 0.625rem !important;
	background: rgba(164, 180, 219, 0.5) !important;
}

.custom-splide .splide__pagination__page.is-active {
	background: #aee6ee !important;
}

iframe::-webkit-scrollbar {
	display: none;
}

iframe {
	border: none;
	overflow-y: hidden !important;
}

.ql-container {
	font-size: 18px !important;
}

.ql-size-huge {
	@apply text-4xl;
}

.ql-size-large {
	@apply text-2xl;
}

.ql-size-small {
	font-size: 12px;
}

.ql-align-center {
	text-align: center;
}

.ql-align-right {
	text-align: right;
}

.ql-align-justify {
	text-align: justify;
}

.ql-indent-1 {
	margin-left: 30px;
}

.ql-indent-2 {
	margin-left: 60px;
}

.ql-indent-3 {
	margin-left: 90px;
}

.ql-indent-4 {
	margin-left: 120px;
}

.ql-indent-5 {
	margin-left: 150px;
}


.toast-header {
	color: var(--Text-Black, #141414);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.toast-okay {
	@apply border-none bg-none;
	color: var(--nea-primary-500, var(--nea-blue, #1B47B4));
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 120% */
}

.react-tabs__tab {
	color: var(--White, #fff) !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
}

.react-tabs__tab--selected {
	color: var(--nea-blue, #1B47B4) !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
}

.empty-icons {
	display: flex !important;
	align-items: center !important;
}

.scrollbar-hidden {
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;
	/* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
	/* Safari and Chrome */
}

.border-8-1 {
	border-radius: 8px;
	border: 1px solid rgba(20, 20, 20, 0.05);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.dash-inner-page {
	flex: 1 1 auto;
}

.nav-items {
	color: #1A1C28;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.login-home-btn {
	width: 8.25rem;
	height: 2.8125rem;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6.25rem;
	border: 1px solid #1B47B4;
	background: #D7E3FF;
	color: #052775;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.signup-home-btn {
	display: flex;
	width: 8.25rem;
	padding: 0.5625rem 2.375rem 0.75rem 2.4375rem;
	justify-content: center;
	align-items: center;
	border-radius: 6.25rem;
	background: #052775;
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4rem;
}

.get-licensed-btn {
	display: flex;
	width: 11.5rem;
	height: 3.4375rem;
	padding: 0.75rem 2.1875rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6.25rem;
	background: #091D50;
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.2rem;
}

.verify-license-btn {
	display: flex;
	width: auto;
	height: 3.4375rem;
	padding: 0.75rem 2.1875rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6.25rem;
	background: transparent;
	border: 1px solid #091D50;
	color: #091D50;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.2rem;
}

.profile-email {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-no {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-link {
	color: #1B47B4;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration-line: underline;
}

.pws-radio {
	color: var(--nea-blue, #1B47B4);
	text-align: center;
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 21.6px */
}

.profile-status {
	display: inline-flex;
	padding: 0.5rem;
	align-items: center;
	gap: 0.25rem;
	border-radius: 0.1875rem;
	background: rgba(87, 168, 99, 0.11);
	color: #34B749;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.profile-unstatus {
	background: rgba(168, 106, 87, 0.21);
	color: #d20000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 28.5px */
}

.slanted-background-2 {
	background-blend-mode: normal;
	background: url('./assets/ladingTopImg.svg');
	background-size: 53%;
	background-repeat: no-repeat;
	background-position: right top;
	width: 100%;
	position: relative;
}

.kenya-text {
	background: linear-gradient(to bottom, #000 35%, #c8001b 60%, #006f30 30%),
		linear-gradient(#000 35%, #c8001b 60%, #006f30 30%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/* 
.menu-item {
	color: var(--Inactive-menu-item, #7792B7);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
} */

.active {
	color: var(--nea-blue, #1B47B4) !important;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.card {
	width: 100%;
	border-radius: 14px 14px 0px 0px;
	text-align: center;
	user-select: none;
}

.cover-photo {
	position: relative;
	background: #1B47B4;
	background-size: cover;
	height: 5.75rem;
	border-radius: 14px 14px 0px 0px;
}

.profile {
	position: absolute;
	width: 100px;
	height: 100px;
	bottom: -40px;
	left: 30%;
	border-radius: 50%;
	padding: 5px;
	z-index: 10000;
	background-color: #fff;
}

.profile-name {
	font-size: 20px;
	color: #000;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.card-main {
	width: 100%;
	border-radius: 16px 16px 0px 0px;
	user-select: none;
}

.cover-photo-main {
	position: relative;
	border-radius: 16px 16px 0px 0px;
	background: rgba(20, 124, 188, 0.1);
	background-size: cover;
	height: 6.5625rem;
}

.profile-main {
	position: absolute;
	width: 139px;
	height: 139px;
	top: 1.81rem;
	left: 15%;
	border-radius: 50%;
	padding: 10px;
	background: #fff;
}

.prof-name {
	color: var(--text-black, #1A1C28);
	text-align: center;
	font-family: "Satoshi";
	font-size: 3.28306rem;
	font-style: normal;
	font-weight: 700;
	line-height: 4.46875rem;
}

.prof-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.prof-edit {
	color: #0873b9;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-card-name {
	color: #000;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px;
	/* 76.563% */
}

.prof-reg {
	color: var(--Text-Black, #333);
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-job {
	color: var(--Text-Black, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-loc {
	color: #7d7d7d;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-head {
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-text {
	color: var(--Text-Black, #333);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-tips {
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-cert-text {
	color: var(--nea-blue, #1B47B4);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-info-head {
	color: #333;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-with-dsablt {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.profile-basic-info {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.edit-prof-desc {
	color: var(--text-black, #222);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.edit-step-head {
	color: #333;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.edit-step-desc {
	color: var(--text-black, #222);
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.label-info {
	color: var(--text-black, #222);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.check-text {
	color: var(--text-black, #222);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.dash-welcome {
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	/* 50.4px */
}

.dash-welcome-text {
	color: #333;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.dash-welcome-verify-text {
	color: var(--nea-blue, #1B47B4);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 33.6px */
}

.cv-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.job-count {
	color: #141414;
	font-size: 29px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.job-apply-text {
	color: var(--nea-blue, #1B47B4);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 30px */
}

.job-filter-text {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.radio-head {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.job-filter-border {
	border-radius: 3px;
	border: 1px solid rgba(20, 20, 20, 0.05);
	background: var(--White, #fff);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.go-back {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 26.4px */
}

.job-info-header {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 31.5px */
}

.job-info-header-cv {
	color: #141414;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.job-info-text {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 31.5px */
}

.job-info-text-head {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.dot-list {
	list-style-type: disc;
	margin-left: 7.5%;
}

.app-status-info {
	color: #141414;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.app-status-text {
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 27px */
}

.app-intv-gray {
	color: #888;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.app-intv-black {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.int-reqs {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.num-list {
	list-style: none;
	counter-reset: item;
}

.num-list li {
	counter-increment: item;
}

.num-list li::before {
	margin-right: 10px;
	content: counter(item) '.';
}

.int-no {
	color: var(--nea-blue, #1B47B4);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px;
	/* 122.5% */
}

.offer-text {
	color: #006f30;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.edit-header {
	color: var(--text-black, #222);
	font-size: 29px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 38.4px */
}

.edit-visible {
	color: var(--Text-Black, #333);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.vis-text {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.private-text {
	color: var(--text-black, #222);
	font-size: 29px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 38.4px */
}

.sure {
	color: var(--Text, #333);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.sure-text {
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.interview {
	color: #181818;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.book {
	color: rgba(0, 0, 0, 0.7);
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 42px */
}

.book-text {
	color: rgba(0, 0, 0, 0.7);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.con-title {
	color: rgba(0, 0, 0, 0.7);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 39px */
}

.login-btn {
	display: flex;
	width: 11.0625rem;
	height: 3rem;
	padding: 0.75rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	flex-shrink: 0;
	border-radius: 1.75rem;
	background: var(--icon-grad, linear-gradient(98deg, #272466 -26.25%, #1B47B4 115.54%));
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.req-btn {
	display: flex;
	width: 254.861px;
	height: 45px;
	padding: 0px 20px 0px 20px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 35px;
	background: #eaeaea;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px;
	/* 122.5% */
}

.guide {
	color: var(--White, #fff);
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.dash-info {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.btn-card {
	width: 200px;
	height: 190px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 2px solid #edf8ff;
	background: var(--White, #fff);
	color: var(--Gray-1, #333);
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	/* 95.455% */
	text-transform: capitalize;
}

.btn-card-active {
	width: 200px;
	height: 190px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 2px solid #edf8ff;
	background: #edf8ff;
	color: var(--darkBlue);
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	/* 95.455% */
	text-transform: capitalize;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-card-active>span {
	text-align: left !important;
}

@media (max-width: 481px) {
	.btn-card {
		width: 200px;
	}

	.btn-card-active {
		width: 200px;
	}
}

.not-pos {
	color: #141414;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 27px */
	text-decoration-line: underline;
}

.not-inst {
	color: #141414;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 24px */
}

.not-by {
	overflow: hidden;
	color: var(--black-100, #1c1c1c);
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 111.111% */
}

.not-time {
	color: var(--black-40, rgba(0, 0, 0, 0.4));
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 112.5% */
}

.not-header {
	color: var(--black-100, #1c1c1c);
	font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 100% */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
	display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.content {
	scrollbar-width: none;
	/* Firefox */
	-ms-overflow-style: none;
	/* IE and Edge */
}

.cv-scroll::-webkit-scrollbar {
	width: 20px;
}

.cv-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.cv-scroll::-webkit-scrollbar-thumb {
	background-color: #D1DAF0;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

.cv-scroll::-webkit-scrollbar-thumb:hover {
	background-color: #D1DAF0;
}

.cv-upload-text {
	color: rgba(0, 0, 0, 0.7);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.cv-upload-text-white {
	color: #fff;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.chat-left {
	border-bottom-left-radius: 50px;
	border-top-right-radius: 80px;
	border-top-left-radius: 8px;
}

.chat-right {
	border-bottom-right-radius: 50px;
	border-top-left-radius: 80px;
	border-top-right-radius: 8px;
}

.cv-title {
	color: var(--Text-Black, #141414);
	font-size: 26px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	/* 36.4px */
}

.job-prev-head {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 180%;
	/* 43.2px */
}

.job-prev-text {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%;
	/* 43.2px */
}

.bg-check-text {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 200%;
	/* 48px */
}

.distress-txt {
	color: var(--White, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}

.distress-head {
	color: var(--White, #fff);
	text-align: center;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.distress-headers {
	color: var(--Text, #333);
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.get-started-btn {
	width: 236px;
	border-radius: 45px;
	border: 2px solid var(--nea-blue, #1B47B4);
	background: var(--White, #fff);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
	color: var(--nea-blue, #1B47B4);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	/* 122.5% */
}

.featured {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 25.2px */
}

.featured-head {
	color: #333;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.home-job-title {
	color: #333;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.home-job-company {
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.home-job-vacancy {
	color: var(--nea-blue, #1B47B4);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.home-job-badge {
	color: #7d5ae2;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	border-radius: 3px;
	background: rgba(125, 90, 226, 0.1);
	line-height: 150%;
	/* 27px */
	padding: 5px;
}

.home-job-info {
	color: var(--Text-Black, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.safety-header {
	color: var(--White, #fff);
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.safety-text {
	color: var(--White, #fff);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.safety-clip {
	clipPath: polygon(0% 100%,
			100% 100%,
			calc(100% - 100px) 0%,
			0% 0%,
			calc(100% - 30px) 0%,
			0% 0%);
}

.verify-header {
	color: var(--White, #fff);
	font-size: 33px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.verify-text {
	color: var(--White, #fff);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.partners-header {
	color: #333;
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.partners-text {
	color: #000;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	/* 153.125% */
	margin-top: 3px;
}

.we-offer {
	color: var(--Text-Black, #141414);
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 43.2px */
	letter-spacing: -0.36px;
}

.we-offer-public {
	color: var(--Text-Black, #141414);
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 33.6px */
}

.we-offer-text {
	color: var(--text-black, #222);
	text-align: justify;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 24px */
}

.home-info-head {
	color: #2e312e;
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: 40px;
	/* 142.857% */
	letter-spacing: -0.06px;
}

.home-info-sub-head {
	color: #2e312e;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	/* 133.333% */
	letter-spacing: -0.06px;
}

.home-info-body {
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 30.01px;
	/* 142.905% */
	letter-spacing: -0.05px;
}

.home-info-feed-name {
	color: var(--text-black, #222);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 21.6px */
}

.home-info-feed-title {
	color: #a7a7a7;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 21.6px */
}

.feed-form-header {
	color: var(--Text, #333);
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 54px */
}

.feed-form-white-head {
	color: var(--White, #fff);
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.feed-form-white-sub-head {
	color: var(--blue-white, #fdfeff);
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 26.4px */
}

.feed-form-rate {
	color: var(--Text-Black, #141414);
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 26.4px */
}

.ant-rate-star path:hover {
	fill: #f79707 !important;
}

.ant-rate-star-full path {
	fill: #f79707 !important;
}

.nea-home-title-sm {
	color: var(--White, #fff);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.nea-home-title-lg {
	color: var(--White, #fff);
	font-size: 34px;
	font-style: normal;
	font-weight: 500;
	line-height: 78.24px;
	/* 230.118% */
	letter-spacing: -1px;
}

.ke-flag-bg {
	/* background-blend-mode: overlay; */
	background-color: #1d1d1d;
	background-image: url('./assets/Kenya.svg');
	background-repeat: no-repeat;
	background-position: center;
	height: 580px;
}

.need-help {
	color: #424242;
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 33.6px */
	letter-spacing: -0.28px;
}

.need-help-text {
	color: #000;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 29.4px */
}

.need-help-btn {
	border-radius: 56px;
	background: #c8001b;
	box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
	display: flex;
	width: 394px;
	height: 65px;
	padding: 16px 28px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: var(--nea-primary-50, #edf8ff);
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 120% */
}

.footer-head {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.575rem;
	letter-spacing: 0.045rem;
	text-transform: uppercase;
}

.footer-text {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
	text-align: left;
}

.home-results-text {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 25.2px */
}

.acc-main-title {
	font-family: 'Satoshi';
}

.acc-title {
	font-family: 'Satoshi';
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 32.4px;
}

.acc-sub-title {
	color: var(--text-black, #222);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 133.333%;
}

.acc-name {
	color: #333;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.acc-sub-name {
	color: var(--text-black, #222);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 24px */
	text-transform: uppercase;
}

.setup-title {
	font-family: 'Satoshi';
	font-size: 2.8125rem;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
}

.heading_heading_company_name {
	color: #141414;
	font-family: "DM Sans";
	font-size: 1.1875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.heading_heading_company_name_small {
	color: #1A1C28;
	font-family: "Satoshi";
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.1125rem;
	white-space: break-spaces;
}

.heading_heading_company_name_small_v2 {
	color: #1A1C28;
	font-family: "Satoshi";
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2.1125rem;
}

.heading_heading_job_name_small {
	color: #1A1C28;
	font-family: "Satoshi";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.7875rem;
}

.heading_heading_job_name_small_v2 {
	color: #1A1C28;
	font-family: Satoshi;
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.7875rem;
}

.heading_heading_company_vacancy {
	color: #1B47B4;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
	/* 140% */
}

.heading_heading_job_details {
	color: #1A1C28;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.heading_heading_job_vacancy {
	color: #141414;
	font-family: Satoshi;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

.heading_heading_job_filter {
	color: #1A1C28;
	font-family: Satoshi;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.275rem;
}

.heading_heading_job_info {
	color: #1A1C28;
	font-family: Satoshi;
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.1125rem;
}

.heading_heading_skills {
	color: var(--text-black, #222);
	font-family: "DM Sans";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.heading_heading_notice {
	color: #322D60;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 0.9375rem;
}

.heading_heading_main {
	color: var(--text-invert, #FFF);
	text-align: center;
	font-family: "Inter", sans-serif;
	font-size: 3rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.heading_heading_hire {
	color: #FFF;
	font-family: "Satoshi";
	font-size: 3.41063rem;
	font-style: normal;
	font-weight: 700;
	line-height: 4.46875rem;
}

.heading_heading_hire_agency {
	color: #FFF;
	font-family: 'Satoshi';
	font-size: 2.10838rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.7625rem;
}

.heading_heading_login {
	font-family: "Satoshi";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	background: var(--icon-grad, linear-gradient(98deg, #272466 -26.25%, #1B47B4 115.54%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.heading_heading_0 {
	color: #052775;
	text-align: center;
	font-family: "Satoshi";
	font-size: 3rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.heading_heading_1 {
	color: var(--Text-inverse-primary, #FFF);
	font-family: "Satoshi";
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

.heading_heading_2 {
	color: var(--text-black, #222);
	font-family: "Satoshi";
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

.heading_heading_3 {
	color: var(--Text-Primary, #212121);
	font-family: "Satoshi";
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

.heading_heading_4 {
	color: var(--Text-primary, #212121);
	font-family: "Satoshi";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
}

.heading_heading_5 {
	color: var(--text-black, #222);
	font-family: "DM Sans";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.display_display_4 {
	color: #141414;
	font-family: Satoshi;
	font-size: 2.8125rem;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
}

.paragraph_paragraph_job_info {
	color: rgba(20, 20, 20, 0.70);
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2rem;
}

.paragraph_paragraph_councelling {
	color: #1B47B4;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	/* 150% */
	text-decoration-line: underline;
}

.paragraph_paragraph_landing {
	color: var(--color-neutral-1000, #000);
	font-family: "Satoshi";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.paragraph_paragraph_1 {
	color: var(--text-black, #222);
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
}

.paragraph_paragraph_2 {
	color: #1B47B4;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
}

.paragraph_paragraph_3 {
	color: var(--nea-blue, #1B47B4);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem;
}

.paragraph_paragraph_5 {
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1rem;
}

.paragraph_paragraph_bb08 {
	color: var(--nea-blue, #1B47B4);
	text-align: center;
	font-family: "Public Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.25rem;
}

.paragraph_paragraph_bb13 {
	color: var(--nea-primary-500, #1B47B4);
	font-family: "DM Sans";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	margin-bottom: 1rem;
}

.paragraph_paragraph_b_4_1125 {
	color: #1B47B4 !important;
	font-family: "DM Sans";
	font-size: 1.125rem !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 120% !important;
	margin-left: 0.31rem !important;
}

.label_label_disability {
	color: #000;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-left: 0.31rem;
}

.label_label_landing_card {
	color: #F4F4F4;
	text-align: center;
	font-family: "Satoshi";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 125%;
}

.label_label_info {
	color: #212121;
	text-align: center;
	font-family: "DM Sans";
	font-size: 2.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.label_label_text {
	color: var(--color-neutral-1000, #000);
	text-align: center;
	font-family: "Satoshi";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.label_label_hire_verification {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
	/* 140% */
}

.label_label_hire_agency {
	color: #FFF;
	font-family: "Satoshi";
	font-size: 1.42969rem;
	font-style: normal;
	font-weight: 500;
	line-height: 3.0875rem;
	/* 215.956% */
}

.label_label_hire_license {
	color: #FFF;
	font-family: "Satoshi";
	font-size: 2.10838rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.7625rem;
	/* 131.024% */
}

.label_label_hire {
	color: #E8EBFA;
	font-family: "Satoshi";
	font-size: 1.42969rem;
	font-style: normal;
	font-weight: 500;
	line-height: 3.0875rem;
	/* 215.956% */
}

.label_label_hire_one {
	color: #FFF;
	font-family: "Satoshi";
	font-size: 2.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 3.0875rem;
}

.label_label_hire_two {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.label_label_footer {
	color: #FFF;
	font-family: "Satoshi";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.label_label_footer_one {
	color: #FFF;
	font-family: Satoshi;
	font-size: 1.28906rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.7875rem;
}

.label_label_id {
	font-family: "DM Sans";
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	background: var(--icon-grad, linear-gradient(98deg, #272466 -26.25%, #1B47B4 115.54%)) !important;
	background-clip: text !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.label_label_1 {
	color: var(--Text-primary, #212121);
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5rem;
}

.label_label_2 {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.label_label_3 {
	color: var(--Text-primary, #212121);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}


.invalid>span {
	opacity: 1 !important;
}

.pagination_number_text {
	color: #626364;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.blue-btn {
	display: flex;
	width: 100%;
	height: 3.125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	background: var(--Button-primary, #1B47B4);
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.blue-outline-btn {
	display: flex;
	width: 100%;
	height: 3.125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	border: 1px solid #1B47B4;
	background: var(--Button-outlined-surface, #FFF);
	color: #1B47B4;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.no-outline-btn {
	display: flex;
	width: 100%;
	height: 3.125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	border: none;
	background: var(--Button-outlined-surface, #FFF);
	color: #1B47B4;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.score-btn {
	height: 2.125rem;
	display: flex;
	padding: 0rem 0.75rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	border-radius: 0.125rem;
	background: #02A548;
	color: #FFF;
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.job-btn {
	display: flex;
	padding: 0.75rem 2.1875rem;
	flex-direction: column;
	align-items: center;
	border-radius: 6.25rem;
	background: linear-gradient(180deg, #4276FC, #1844B5);
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.2rem;
}

.black-btn-text {
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.2rem;
}

.job-filter-btn {
	display: flex;
	width: 100%;
	min-height: 2.94938rem;
	height: auto;
	padding: 0.65rem 2.57425rem 0.72438rem 1.3125rem;
	border-radius: 0.9375rem;
	border: 1px solid #E8EFFF;
	background: #E8EFFF;
	color: #1A1C28;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.job-filter-btn-active {
	display: flex;
	width: 100%;
	min-height: 2.94938rem;
	height: auto;
	padding: 0.65rem 2.57425rem 0.72438rem 1.3125rem;
	border-radius: 0.9375rem;
	border-radius: 0.9375rem;
	border: 1px solid #1B47B4;
	background: #FDFDFD;
	color: #1A1C28;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.575rem;
}

.black-btn {
	display: flex;
	width: 15.16688rem;
	height: 30px;
	max-width: 100% !important;
	/* padding: 0.8125rem 0rem 0.76188rem 0rem; */
	padding: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 6.25rem;
	border: 1px solid #CCC;
	background: #1A1C28;
	margin-left: 10px;
}

.black-btn svg {
	margin-top: 1px;
}

.black-outline-btn {
	display: flex;
	width: 15.16688rem;
	height: 30px;
	max-width: 100% !important;
	/* padding: 0.8125rem 0rem 0.76188rem 0rem; */
	padding: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 6.25rem;
	border: 1px solid #1A1C28;
	background: white;
	margin-right: 10px;
}

.browse-jobs-btn {
	display: flex;
	width: 24.0625rem;
	height: 4.625rem;
	padding: 1.3125rem 2.75rem 1.3125rem 6.375rem;
	justify-content: flex-end;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6.25rem;
	background: linear-gradient(98deg, #272466 31.03%, #1B47B4 146.37%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.2rem;
}

.hire-talent-btn {
	width: 11.3125rem;
	display: inline-flex;
	height: 3.4375rem;
	padding: 0.75rem 2.1875rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6.25rem;
	background: #D7E3FF;
	color: #322D60;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.2rem;
}

.ready_to_apply_card {
	width: 100%;
	height: 13.62rem;
	display: flex;
	padding: 3.125rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.87438rem;
	align-self: stretch;
	border-radius: 0.9375rem;
	background: #1A1C28;
}

.ready_to_apply_header {
	color: #FFF;
	font-family: 'Satoshi';
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.1125rem;
}

.ready_to_apply_btn {
	width: fit-content;
	display: flex;
	padding: 0.75rem 2.1875rem;
	justify-content: center;
	align-items: flex-start;
	border-radius: 6.25rem;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1844B5 100%), #4276FC;
	color: #FFF;
	text-align: center;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.2rem;
}

.go-back {
	position: absolute;
	right: 1.27rem;
	top: 1.25rem;
	display: flex;
	width: 2rem;
	height: 2rem;
	padding: 0.4375rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 1.5rem;
	border: 0.5px solid rgba(245, 239, 235, 0.32);
	background: var(--Icon-surface, #EDF8FF);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(90px);
}

.job_filter {
	display: flex;
	padding: 0.75rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	border-radius: 1.25rem;
	background: var(--Icon-surface, #EDF8FF);
}

.job_filter_text {
	color: var(--Icon-active, #1B47B4);
	font-family: "DM Sans";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}


.animated-notice {
	max-width: fit-content;
	display: inline-block;
	/* overflow: hidden; */
	white-space: nowrap;
	position: relative;
	animation: move-words 35s linear infinite;
	margin: 0;
}

@keyframes move-words {
	0% {
		left: 100%;
	}

	100% {
		left: -100%;
	}
}

@keyframes showup {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes slidein {
	0% {
		margin-left: -800px;
	}

	20% {
		margin-left: -800px;
	}

	35% {
		margin-left: 0px;
	}

	100% {
		margin-left: 0px;
	}
}

@keyframes reveal {
	0% {
		opacity: 0;
		width: 0px;
	}

	20% {
		opacity: 1;
		width: 20%;
	}

	30% {
		width: 40%;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		width: auto;
	}
}

.dashboard_card {
	width: 100%;
	height: 15.1875rem;
	flex-shrink: 0;
	border-radius: 0.75rem;
	border: 1px solid #1B47B4;
	background: #052775;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* .dashboard_card_title{
	width: 9.0625rem;
	height: 3.875rem;
	display: flex;
	justify-content: center;
	align-self: center;
	border: 1px solid #1C46B3;
	background: #152F94;
} */

.dashboard_card:hover {
	width: 100%;
	height: 15.1875rem;
	flex-shrink: 0;
	border-radius: 0.75rem;
	border: 1px solid #103590;
	background: #00216C;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashboard_card_svg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.5rem;
	height: 1.5rem;
	padding: 0.31rem;
	margin-right: 1.56rem;
	border-radius: 50%;
}

.dashboard_card:hover .dashboard_card_svg {
	background: #E2E8FF;
}

.dashboard_card:hover svg>path {
	stroke: #272466;
}

.hire-bg {
	background: url("./assets/landing/home9.png");
	background-position: center 62%;
	background-size: 130%;
	background-repeat: no-repeat;
}

.employer-img {
	width: 100%;
	height: 42rem;
	background-position: center;
	background-size: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
}

.social-media-icon {
	display: flex;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.75rem 0.78125rem 0.8125rem 0.78125rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6.25rem;
	background: #E2E8FF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.matching-card {
	min-width: 8.9375rem;
	width: fit-content;
	height: 2.125rem;
	display: flex;
	padding: 0rem 0.75rem;
	justify-content: center;
	align-items: center;
	border-radius: 0.625rem;
	background: #EAFF8F;
}

.recommended-card {
	min-width: 12.6875rem;
	width: fit-content;
	height: 2.125rem;
	display: flex;
	padding: 0rem 0.75rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	border-radius: 0.625rem;
	background: var(--Surface-Info-background-badge, #D1DAF0);
}

.popular-search-text {
	color: var(--text-black, #1A1C28);
	text-align: center;
	font-family: "Satoshi";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5rem;
}

.resume-back-btn {
	display: flex;
	width: 15rem;
	height: 3.125rem;
	padding: 0.75rem 3rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	border: 1px solid var(--Icon-active, #1B47B4);
	background: var(--Button-outlined-surface, #FFF);
	color: var(--Icon-active, #1B47B4);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.resume-next-btn {
	display: flex;
	height: 3.125rem;
	padding: 0.75rem 3rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	background: var(--Button-primary, #1B47B4);
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.login_title {
	color: var(--Primary-White, #FFF);
	font-family: "Satoshi";
	font-size: 3rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.login_sub_title {
	color: #D1DAF0;
	font-family: "DM Sans";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 400;
	line-height: 125%;
}

.login_text {
	color: var(--Surface-Info-background-badge, #D1DAF0);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.reg_card {
	min-height: 12.1875rem;
	/* width: 100%; */
	height: auto;
	border-radius: 0.83731rem;
	background: #F9FBFF;
}

.reg_card_active {
	min-height: 12.1875rem;
	/* width: 100%; */
	height: auto;
	border-radius: 0.83731rem;
	border: 2px solid #1F3FA1;
	background: #E0E9FF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.reg_card_active span {
	font-family: "Satoshi";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
	background: var(--icon-grad, linear-gradient(98deg, #272466 -26.25%, #1B47B4 115.54%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.reg_card_active svg>path {
	stroke: #1F3FA1 !important;
}

.reg_btn_disabled {
	display: flex;
	width: 11.0625rem;
	height: 3rem;
	padding: 0.75rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	flex-shrink: 0;
	border-radius: 1.75rem;
	opacity: 0.5;
	background: var(--grad-blue, linear-gradient(98deg, #142B93 -26.25%, #1B47B4 115.54%));
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.reg_btn {
	display: flex;
	width: 11.0625rem;
	height: 3rem;
	padding: 0.75rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	flex-shrink: 0;
	border-radius: 1.75rem;
	border: 1.5px solid #FFF;
	background: var(--grad-blue, linear-gradient(98deg, #142B93 -26.25%, #1B47B4 115.54%));
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.back-btn {
	display: flex;
	height: 3.125rem;
	padding: 0.75rem 1.5rem;
	justify-content: center;
	align-items: center;
	border-radius: 1.75rem;
	color: var(--Text-secondary, #616161);
	text-align: right;
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.input-otp input {
	display: flex !important;
	min-width: 2.5rem !important;
	width: 2.5rem !important;
	height: 2.375rem !important;
	/* padding: 0.625rem 2.5625rem 0.625rem 0.8125rem !important; */
	flex-direction: column !important;
	align-items: flex-start !important;
	border-radius: 0.25rem !important;
	border: 1px solid #D4D4D8 !important;
	background: #FFF !important;
	margin-right: .37rem !important;
}

.modal-cancel-btn {
	display: flex;
	width: 5.9375rem;
	height: 2.8125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	border: 1px solid var(--Text-primary, #212121);
	background: var(--Button-outlined-surface, #FFF);
	color: var(--Text-primary, #212121);
	text-align: right;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.modal-action-btn {
	display: flex;
	width: 5.9375rem;
	height: 2.8125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	background: var(--Button-outlined-surface, #1B47B4);
	color: var(--Text-primary, #fff);
	text-align: right;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}