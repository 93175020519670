/* Media Query for Mobile Devices */
@media (max-width: 728px) {
    .heading_heading_main{
        font-size: 2rem !important;
    }

    .heading_heading_0{
        font-size: 2rem !important;
    }

    .browse-jobs-btn{
        width: 100% !important;
        padding: 1.375rem !important;
    }
}

 /* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
    body {
        background-color: yellow;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    body {
        background-color: blue;
    }
}